"use strict";

import React, { useCallback, useReducer, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import { PaymentFormGS as PF } from "@dewz/ui";
import { Loader } from "../loaders";

import { Section } from "../shared";
import { colors } from "../../colors";

const LoginButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  flex-wrap: wrap;
  margin-top: 10px;
`;

const LoginButton = styled.button`
  color: ${colors.linkColor};
  border: none;
  background: unset;
  text-decoration: underline;
  font-weight: bold;
  text-shadow: 0 0 1px white;
  font-size: 18px;
`;

const defaultState = {
  cardNumber: "",
  cardExpirationMonth: "",
  cardExpirationYear: "",
  cardCvv: "",
  billingFirstName: "",
  billingLastName: "",
  billingAddress1: "",
  billingAddress2: "",
  billingCity: "",
  billingState: "",
  billingZipCode: "",
  billingCountry: "",
  billingPhone: "",
  billingCompany: "",
  email: "",
  password: "",
  passwordConfirm: "",
  membership: "12",
  paymentType: "card",
  accessCode: ""
};

const dispatch = (state, action) => {
  switch (action.type) {
    case "SET_ERROR": {
      return Object.assign({}, state, action.payload);
    }
    case "SET_ONE": {
      const newState = { ...state };
      newState[action.payload.name] = action.payload.value;
      return newState;
    }
    case "SET_SUBMIT": {
      return {
        ...state,
        isSubmitting: action.payload
      };
    }
    case "RESET_FORM": {
      return {
        ...state,
        cardNumber: "",
        cardExpirationMonth: "",
        cardExpirationYear: "",
        cardCvv: "",
        billingFirstName: "",
        billingLastName: "",
        billingAddress1: "",
        billingAddress2: "",
        billingCity: "",
        billingState: "",
        billingZipCode: "",
        billingCountry: "",
        billingCompany: "",
        billingPhone: "",
        email: "",
        password: "",
        passwordConfirm: "",
        membership: "12",
        paymentType: "card",
        accessCode: ""
      };
    }
    case "SET_FORM": {
      return {
        ...state,
        status: action.payload
      };
    }
    default:
      return { ...state };
  }
};

export const PaymentForm = ({ setForm, userId, loginEmail, registeredPassword, firstName, lastName }) => {
  const [isLoading, setLoading] = useState(false);
  const [isAuthorized, setAuthorized] = useState(false);
  const [registeredList, setRegistered] = useState([]);
  const [currUserId, setUserId] = useState(userId);
  console.log({ userId, registeredPassword });
  const [
    {
      cardNumber,
      cardExpirationMonth,
      cardExpirationYear,
      cardCvv,
      billingFirstName,
      billingLastName,
      billingAddress1,
      billingAddress2,
      billingCity,
      billingState,
      billingZipCode,
      billingCountry,
      billingCompany,
      billingPhone,
      email,
      password,
      passwordConfirm,
      membership,
      paymentType,
      accessCode
    },
    componentDispatch
  ] = useReducer(
    dispatch,
    Object.assign(defaultState, {
      email: loginEmail,
      password: userId !== null ? registeredPassword : "",
      billingFirstName: userId !== null ? firstName : "",
      billingLastName: userId !== null ? lastName : ""
    })
  );

  const isNewUser = useMemo(() => {
    return userId === "n/a";
  }, [userId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const setLoginForm = useCallback(() => {
    if (setForm) {
      setForm("login", userId);
    }
  }, [setForm, userId]);

  const handlePayment = useCallback(
    (values, id) => {
      fetch(`${process.env.REACT_APP_REST_ENDPOINT}/activate`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json"
        },
        redirect: "follow",
        referrer: "no-referrer",
        body: JSON.stringify({
          cardNumber,
          cardExpirationMonth: cardExpirationMonth.padStart(2, "0"),
          cardExpirationYear,
          cardCvv,
          billingFirstName,
          billingLastName,
          billingAddress1,
          billingAddress2,
          billingCity,
          billingState,
          billingZipCode,
          billingCountry,
          billingCompany,
          billingPhone,
          email,
          password,
          passwordConfirm,
          membership,
          paymentType,
          accessCode
        })
      })
        .then(
          (res) => {
            res.json().then((json) => {
              setLoading(false);
              if (res.ok) {
                toast.success("Payment Approved! Welcome to Green Saver!", {
                  position: "bottom-center",
                  hideProgressBar: true,
                  pauseOnHover: false,
                  closeButton: false
                });
                if (json?.success === "email_error") {
                  toast.error(
                    `Error occurred while emailing receipt. Please confirm '${email}' is the correct email. 
                          If this is the incorrect email, please update it upon login.`,
                    {
                      position: "bottom-center",
                      hideProgressBar: true,
                      pauseOnHover: false,
                      closeButton: false,
                      autoClose: 6000
                    }
                  );
                }
                // updateUser({
                //   variables: {
                //     changes: { userStatus: "active", lastUpdate: new Date().toString() },
                //     userId: id
                //   }
                // })
                setForm("congrats", id);
              } else {
                let message = "Transaction Failed";
                if (typeof json?.error === "string") {
                  message = json.error;
                }
                toast.error(message, {
                  position: "bottom-center",
                  hideProgressBar: true,
                  pauseOnHover: false,
                  closeButton: false
                });
              }
            });
            setAuthorized(false);
          },
          (error) => {
            setLoading(false);
            let message = "Transaction Failed";
            if (typeof error === "string") {
              message = error;
            }
            toast.error(message, {
              position: "bottom-center",
              hideProgressBar: true,
              pauseOnHover: false,
              closeButton: false
            });
          }
        )
        .catch((err) => console.log(err));
    },
    [
      cardNumber,
      cardExpirationMonth,
      cardExpirationYear,
      cardCvv,
      billingFirstName,
      billingLastName,
      billingAddress1,
      billingAddress2,
      billingCity,
      billingState,
      billingZipCode,
      billingCountry,
      billingCompany,
      billingPhone,
      email,
      password,
      passwordConfirm,
      membership,
      paymentType,
      accessCode,
      setForm
    ]
  );

  const handleChange = useCallback(
    ({ name, value }) => {
      componentDispatch({
        type: "SET_ONE",
        payload: { name, value }
      });
    },
    [componentDispatch]
  );

  const handleSubmit = useCallback(
    async function (values) {
      const submitAsync = async () => {
        setLoading(true);
        console.log({ registeredList });
        if (isNewUser && !registeredList.includes(email)) {
          const data = {
            email: values.email,
            password: values.password,
            first: values.billingFirstName,
            last: values.billingLastName,
            phone: values.billingPhone,
            address: `${values.billingAddress1}${values.billingAddress2 ? values.billingAddress2 : ""}`,
            city: values.billingCity,
            state: values.billingState,
            zipCode: values.billingZipCode,
            userType: "member",
            userStatus: "inactive",
            notes: "",
            created: new Date().toString(),
            createSession: true
          };
          const response = await fetch(`${process.env.REACT_APP_REST_ENDPOINT}/register`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json; charset=utf-8",
              Accept: "application/json"
            },
            redirect: "follow",
            referrer: "no-referrer",
            body: JSON.stringify(data)
          });
          componentDispatch({
            type: "SET_SUBMIT",
            payload: false
          });
          if (response.ok) {
            console.log("ok?");
            const registeredEmails = [data.email].concat(registeredList);
            setRegistered(registeredEmails);
            response.json().then(
              (session) => {
                handlePayment(values, session.id);
                setUserId(session.id);
              },
              (reason) => {
                componentDispatch({
                  type: "SET_ERROR",
                  payload: { error: reason.toString(), hasNewError: true, isSubmitting: false }
                });
              }
            );
          } else {
            setLoading(false);
            response.json().then(
              (reason) => {
                toast.error(reason.error, {
                  position: "bottom-center",
                  hideProgressBar: true,
                  pauseOnHover: false,
                  closeButton: false
                });
              },
              (reason) => {
                toast.error(reason.toString(), {
                  position: "bottom-center",
                  hideProgressBar: true,
                  pauseOnHover: false,
                  closeButton: false
                });
              }
            );
          }
        } else {
          console.log("handlepay?");
          handlePayment(values, currUserId);
        }
      };
      submitAsync();
    },
    [registeredList, isNewUser, email, handlePayment, currUserId]
  );

  return (
    <>
      {isLoading && <Loader />}
      <Section style={{ overflowY: "auto" }}>
        <PF
          values={{
            cardNumber,
            cardExpirationMonth,
            cardExpirationYear,
            cardCvv,
            billingFirstName,
            billingLastName,
            billingAddress1,
            billingAddress2,
            billingCity,
            billingState,
            billingZipCode,
            billingCountry,
            billingPhone,
            billingCompany,
            email,
            password,
            passwordConfirm,
            membership,
            paymentType,
            accessCode
          }}
          // imageSrc={paymentHeader}
          onChange={handleChange}
          onSubmit={handleSubmit}
          isNewUser={isNewUser}
          // membershipMonthLabel="$4 - Per Month"
          membershipYearLabel="$49 - Offers Expire 1/1/2026"
        />
        <LoginButtonWrapper>
          Already a member?{" "}
          <LoginButton name="login" onClick={setLoginForm}>
            Login Here
          </LoginButton>
        </LoginButtonWrapper>
      </Section>
    </>
  );
};
