import React, { useReducer, useCallback } from "react";
import { MdFileUpload } from "react-icons/md";
import { TableButton } from "../table";
import { FileUpload } from "../inputs";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { toast } from "react-toastify";
import { useFilterViewModelContext, useTableViewModelContext } from "..";
import moment from "moment";

const CREATE_USER = gql`
  mutation (
    $address: String
    $city: String
    $email: String!
    $firstName: String!
    $lastName: String!
    $notes: String
    $password: String!
    $phone: String
    $state: String
    $userStatus: String!
    $userType: String!
    $zipCode: String
    $created: String!
    $venues: [ID]
  ) {
    createUser(
      address: $address
      city: $city
      email: $email
      firstName: $firstName
      lastName: $lastName
      notes: $notes
      password: $password
      phone: $phone
      created: $created
      state: $state
      userStatus: $userStatus
      userType: $userType
      zipCode: $zipCode
      venues: $venues
    ) {
      userId
    }
  }
`;

const defaultState = {
  inputId: "hidden_upload_file_input",
  isSubmitting: false,
  dirtyFields: [],
  isModalVisible: false
};

const importDispatch = (state, action) => {
  switch (action.type) {
    case "SET_INPUT_ID": {
      const newState = { ...state };
      newState.inputId = action.payload;
      return newState;
    }
    case "SHOW_MODAL": {
      return {
        ...state,
        isModalVisible: action.payload
      };
    }
    case "START_SAVE": {
      return {
        ...state,
        isSubmitting: true
      };
    }
    case "STOP_SAVE": {
      return {
        ...state,
        isSubmitting: false,
        dirtyFields: []
      };
    }
    default:
      return { ...state };
  }
};

// OLD / BETTER import
// const handleFileUpload = useCallback(
//   (file) => {
//     const lines = file.split("\n");
//     const delimiter = ",";
//     const attributeMap = lines[0].split(delimiter);
//     let failed = lines.map(async (line, idx) => {
//       if (idx === 0 || line.length === 0) {
//         return undefined;
//       }
//       const attributes = line.split(delimiter);
//       const variables = {};
//       attributes.forEach((attr, attrIdx) => {
//         variables[attributeMap[attrIdx]] = attr;
//       });
//       const ok = await createUser({
//         variables
//       }).then(
//         (response) => {
//           if (response.data) {
//             return true;
//           }
//         },
//         (reject) => {
//           return false;
//         }
//       );
//       return ok;
//     });
//     Promise.all(failed)
//       .then((records) => {
//         const numFailed = records.filter((status) => status === false).length;
//         const success = lines.length - records.filter((status) => status === undefined).length - numFailed;
//         if (success > 0) {
//           toast.success(`Imported ${success} successfully.`, {
//             position: "bottom-center",
//             hideProgressBar: true,
//             pauseOnHover: false,
//             closeButton: false
//           });
//         }
//         if (numFailed) {
//           toast.error(`Failed to import ${numFailed} users.`, {
//             position: "bottom-center",
//             hideProgressBar: true,
//             pauseOnHover: false,
//             closeButton: true,
//             autoClose: false
//           });
//         }
//       })
//       .finally(() => {
//         if (onImportFinish) {
//           onImportFinish();
//         }
//       });
//   },
//   [onImportFinish]
// );

const buttonSize = "2em";
export const ImportButton = ({ table, onImportFinish }) => {
  const [createUser] = useMutation(CREATE_USER);
  const [{ inputId }, componentDispatch] = useReducer(importDispatch, Object.assign({}, defaultState));
  const [filterData, filterDispatch] = useFilterViewModelContext();
  const [, tableDispatch] = useTableViewModelContext();

  const openFileDialog = useCallback(() => {
    document.getElementById(inputId).click();
  }, [inputId]);

  const handleFileUpload = useCallback(
    (file) => {
      const created = new Date().toString();
      const userStatus = "inactive";
      const userType = "member";
      const lines = file.split("\n");
      const delimiter = ",";
      const attributeMap = [
        "email",
        "password",
        "firstName",
        "lastName",
        "phone",
        "address",
        "city",
        "state",
        "zipCode"
      ];
      let failed = lines.map(async (line, idx) => {
        const attributes = line.split(delimiter);
        const variables = {};
        attributes.forEach((attr, attrIdx) => {
          variables[attributeMap[attrIdx]] = attr;
        });
        variables["created"] = created;
        variables["userStatus"] = userStatus;
        variables["userType"] = userType;
        const ok = await createUser({
          variables
        }).then(
          (response) => {
            if (response.data) {
              return true;
            }
          },
          (reject) => {
            return false;
          }
        );
        return ok;
      });
      let filters = {
        Name: "",
        Email: "",
        Status: "",
        Role: "",
        Redemptions: "",
        Created: ""
      };
      Promise.all(failed)
        .then((records) => {
          const numFailed = records.filter((status) => status === false).length;
          const success = lines.length - records.filter((status) => status === undefined).length - numFailed;
          if (success > 0) {
            toast.success(`Imported ${success} successfully.`, {
              position: "bottom-center",
              hideProgressBar: true,
              pauseOnHover: false,
              closeButton: false
            });
            // todo: have dispatch take in array of actions
            filterDispatch({
              type: "RESET_FILTERS",
              payload: "member"
            });
            filterDispatch({
              type: "SET_VALUE",
              payload: { table: "member", name: "Created", value: moment(created).format("M/D/YY HH:mm") }
            });
            filters["Created"] = moment(created).format("M/D/YY HH:mm");
          }
          if (numFailed) {
            toast.error(`Failed to import ${numFailed} users.`, {
              position: "bottom-center",
              hideProgressBar: true,
              pauseOnHover: false,
              closeButton: true,
              autoClose: false
            });
          }
        })
        .finally(() => {
          if (onImportFinish) {
            onImportFinish(filters);
          }
        });
    },
    [createUser, filterDispatch, onImportFinish]
  );

  return (
    <>
      <TableButton
        key={`${table}_export`}
        icon={<MdFileUpload size={buttonSize} />}
        text="Import Data"
        handleClick={openFileDialog}
      >
        <FileUpload id={inputId} name="import" onChange={handleFileUpload} />
      </TableButton>
    </>
  );
};
