import React from "react";
import { BrowserRouter } from "react-router-dom";
import ApolloClient from "apollo-boost";
import { ApolloProvider } from "@apollo/react-hooks";
import {
  TableProvider,
  SessionProvider,
  SelectProvider,
  FilterProvider,
  SearchProvider,
  AnchorProvider,
  ClientProvider,
  VenueNavProvider,
  Routes,
  VersionCheck,
  Copyright
} from "./components";
import { getCookie } from "./utility";
import { ThemeProvider } from "styled-components";
import { useUIThemeContext } from "@dewz/ui";

function App() {
  const client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    headers: {
      AUTHTOKEN: getCookie("uuid")
    }
  });
  const [themeData] = useUIThemeContext();

  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={themeData.themes["light"]}>
        <SessionProvider>
          <TableProvider>
            <FilterProvider>
              <SelectProvider>
                <ClientProvider>
                  <BrowserRouter>
                    <AnchorProvider>
                      <SearchProvider>
                        <VenueNavProvider>
                          {/* <VersionCheck /> */}
                          <Routes />
                        </VenueNavProvider>
                      </SearchProvider>
                    </AnchorProvider>
                  </BrowserRouter>
                </ClientProvider>
              </SelectProvider>
            </FilterProvider>
          </TableProvider>
        </SessionProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
